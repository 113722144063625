import Page from '@components/page/Page'

/**
 *
 * @see https://nextjs.org/docs/pages/building-your-application/routing/custom-error
 */
const Custom404: React.FC = () => {
  return (
    <Page>
      <Page.Message header="Oh no!">
        <p>It looks like you've hit a page that doesn't exist.</p>
      </Page.Message>
    </Page>
  )
}

export default Custom404
